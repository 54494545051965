import React, { useState } from 'react';

const PhotoPreferenceForm = ({ onPreferencesSubmit, onRetrain }) => {
  const [preferences, setPreferences] = useState({
    mood: '',
    style: '',
    useCase: '',
    lighting: '',
    background: '',
    attire: ''
  });

  const handleChange = (e) => {
    setPreferences({
      ...preferences,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (Object.values(preferences).every(val => val !== '')) {
      const prompt = `I want a ${preferences.style} photo with a ${preferences.background} background, ` +
                      `where I look ${preferences.mood} in ${preferences.attire} attire, under ${preferences.lighting} lighting for my ${preferences.useCase}.`;
      console.log('Prompt submitted:', prompt);

      const token = localStorage.getItem('access_token'); // Get the JWT token from localStorage

      try {
        const url = `${process.env.REACT_APP_BACKEND_URL}/images/generate_image`;
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`  // Include the JWT token in the Authorization header
          },
          body: JSON.stringify({ prompt }),
          mode: 'cors'
        });

        const result = await response.json();
        if (response.ok) {
          onPreferencesSubmit(result); // Pass the result to the callback
        } else {
          throw new Error(result.error || 'Failed to generate image');
        }
      } catch (error) {
        alert(`Error: ${error.message}`);
      }
    } else {
      alert('Please complete all selections to create your perfect photo!');
    }
  };

  const handleRetrain = () => {
    onRetrain(); // This function is passed from App.js and will reset the current step to CameraCapture
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Tell us about your ideal portrait!</h2>
      <div>
        <p>
          I want a <strong><select name="style" value={preferences.style} onChange={handleChange}>
            <option value="">[select style]</option>
            <option value="classic">classic</option>
            <option value="modern">modern</option>
            <option value="vintage">vintage</option>
            <option value="artistic">artistic</option>
          </select></strong> photo with a <strong><select name="background" value={preferences.background} onChange={handleChange}>
            <option value="">[select background]</option>
            <option value="studio">studio</option>
            <option value="urban">urban</option>
            <option value="nature">nature</option>
            <option value="abstract">abstract</option>
          </select></strong> background, where I look <strong><select name="mood" value={preferences.mood} onChange={handleChange}>
            <option value="">[select mood]</option>
            <option value="serious">serious</option>
            <option value="smiling">smiling</option>
            <option value="thoughtful">thoughtful</option>
            <option value="joyful">joyful</option>
          </select></strong> in <strong><select name="attire" value={preferences.attire} onChange={handleChange}>
            <option value="">[select attire]</option>
            <option value="casual">casual</option>
            <option value="formal">formal</option>
            <option value="sporty">sporty</option>
            <option value="elegant">elegant</option>
          </select></strong> attire, under <strong><select name="lighting" value={preferences.lighting} onChange={handleChange}>
            <option value="">[select lighting]</option>
            <option value="natural">natural</option>
            <option value="studio">studio</option>
            <option value="dramatic">dramatic</option>
          </select></strong> lighting for my <strong><select name="useCase" value={preferences.useCase} onChange={handleChange}>
            <option value="">[use case]</option>
            <option value="LinkedIn profile">LinkedIn profile</option>
            <option value="Tinder bio">Tinder bio</option>
            <option value="Portfolio">portfolio</option>
          </select></strong>.
        </p>
      </div>
      <button type="submit" disabled={Object.values(preferences).some(val => val === '')}>Create My Portrait</button>
      <button type="button" onClick={handleRetrain}>Force Retrain Model</button>
    </form>
  );
};

export default PhotoPreferenceForm;
