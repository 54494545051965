import React from 'react';
import { useFrame } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei';

const StudioEquipment = () => {
    const { scene } = useGLTF('/scene.gltf');  // Load the GLTF model from the public directory

    // Rotate the model slowly around the y-axis
    useFrame(() => {
        scene.rotation.y += 0.001;
    });

    return <primitive object={scene} position={[0, -5, 0]} />;
};

export default StudioEquipment;
