import React, { useState } from 'react';
import CameraCapture from './components/CameraCapture';
import PhotoPreferenceForm from './components/PhotoPreferenceForm';
import AuthScreen from './components/AuthScreen';
import './App.css';

function App() {
  const [currentStep, setCurrentStep] = useState(1);
  const [imageUrl, setImageUrl] = useState('');

  const handleAuthenticationComplete = (authenticated, modelTrained) => {
    if (authenticated) {
      setCurrentStep(modelTrained ? 3 : 2);
    }
  };

  const handleCompleteCapture = () => {
    // This function is called when training is complete
    setCurrentStep(3); // Transition to preferences form
  };

  const handlePreferencesSubmit = (preferences) => {
    console.log('Final Preferences:', preferences);
    setImageUrl(preferences.image_url); // Store the image URL from preferences
    setCurrentStep(4); // Move to final step or completion
  };

  const handleEditPreferences = () => {
    setCurrentStep(3); // Allow editing preferences again without logging out
  };

  const handleRetrain = () => {
    setCurrentStep(2); // Go back to camera capture for retraining
  };

  return (
    <div className="App">
      {currentStep === 1 && <AuthScreen onAuthComplete={handleAuthenticationComplete} />}
      {currentStep === 2 && <CameraCapture onComplete={handleCompleteCapture} />}
      {currentStep === 3 && <PhotoPreferenceForm onPreferencesSubmit={handlePreferencesSubmit} onRetrain={handleRetrain} />}
      {currentStep === 4 && imageUrl && (
        <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', background: `url(${imageUrl}) no-repeat center center`, backgroundSize: 'cover', zIndex: 1000 }}>
          <button onClick={handleEditPreferences} style={{ position: 'absolute', top: 20, right: 20 }}>Edit Preferences</button>
        </div>
      )}
    </div>
  );
}

export default App;