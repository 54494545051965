import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CameraCapture = ({ onComplete }) => {
  const [photos, setPhotos] = useState([]);
  const [currentAngle, setCurrentAngle] = useState(0);
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(null);
  const [cameraActive, setCameraActive] = useState(true);
  const [reviewMode, setReviewMode] = useState(false);
  const [trainingTaskId, setTrainingTaskId] = useState(null);
  const [trainingStatus, setTrainingStatus] = useState(null);
  const videoRef = useRef(null);

  const angles = [
    'Look directly at the camera with a soft, approachable smile.',
    'Turn your head to the right and gaze into the distance, reflecting curiosity.',
    'Turn your head to the left with a light smile, as if engaging in a pleasant conversation.',
    'Tilt your head up slightly and look upward, imagining future possibilities.',
    'Maintain a serious expression, looking straight past the camera, as if deep in thought.',
    'Laugh heartily while looking slightly to your right, showing your fun and carefree side.',
    'Raise your eyebrows and look directly at the camera, expressing surprise or intrigue.',
    'Smile warmly while looking directly at the camera, giving a welcoming vibe.',
    'Turn your body to the left, looking over your shoulder with a mysterious glance.',
    'Assume a confident posture, looking slightly downward with a strong, commanding gaze.',
    'Relax your face and look down towards your feet, giving a calm, introspective expression.',
    'Squint slightly and look upwards as if evaluating a complex idea.',
    'Turn your head slightly left, open your mouth as if in mid-conversation, showing engagement.',
    'Give a playful wink towards the camera with a mischievous smile.',
    'Look over your left shoulder, back towards the camera, portraying a sense of nostalgia or reflection.'
];

  useEffect(() => {
    const manageCamera = (activate) => {
      if (activate) {
        navigator.mediaDevices.getUserMedia({ video: true })
          .then(stream => {
            if (videoRef.current) {
              videoRef.current.srcObject = stream;
              console.log("Camera stream started.");
            }
          })
          .catch(err => {
            console.error("Error accessing camera:", err);
            alert("Camera is not available. Please check your camera settings.");
            setCameraActive(false); // Disable camera if access fails
          });
      } else if (videoRef.current && videoRef.current.srcObject) {
        videoRef.current.srcObject.getTracks().forEach(track => {
          track.stop();
          console.log("Camera track stopped.");
        });
        videoRef.current.srcObject = null;
      }
    };

    manageCamera(cameraActive);
    return () => manageCamera(false); // Cleanup function to stop camera on component unmount
  }, [cameraActive]);

  const capturePhoto = () => {
    if (!videoRef.current || !videoRef.current.srcObject) {
      console.error("Camera is not active when trying to capture.");
      alert("Camera is not available.");
      return;
    }
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
    const photoData = canvas.toDataURL('image/jpeg');
    console.log("Photo captured.");

    const updatedPhotos = [...photos];
    if (selectedPhotoIndex !== null) {
      // Replace the photo at the selected index
      updatedPhotos[selectedPhotoIndex] = { angle: angles[selectedPhotoIndex], data: photoData };
      setPhotos(updatedPhotos);
      setSelectedPhotoIndex(null);
      setCameraActive(false);  // Turn off the camera after recapture
    } else {
      // Add new photo if not in recapture mode
      updatedPhotos.push({ angle: angles[currentAngle], data: photoData });
      setPhotos(updatedPhotos);
      if (currentAngle + 1 < angles.length) {
        setCurrentAngle(currentAngle + 1);
      } else {
        setReviewMode(true);  // Enable review mode after all photos are captured
        setCameraActive(false);  // Turn off the camera
      }
    }
  };

  const handleImageClick = (index) => {
    setSelectedPhotoIndex(index);
    setCameraActive(false);  // Ensure the camera is off when a photo is selected
  };

  const handleRecapture = () => {
    setCameraActive(true);  // Enable the camera for recapture
  };
  
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: true,
    nextArrow: <motion.button type="button" className="slick-next" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>Next</motion.button>,
    prevArrow: <motion.button type="button" className="slick-prev" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>Prev</motion.button>
  };

  const handleNext = async () => {
    const imagesData = photos.map(photo => ({
        angle: photo.angle,
        data: photo.data  // This is the base64 image data
    }));

    const token = localStorage.getItem('access_token');  // Retrieve the JWT token from localStorage

    try {
        const url = `${process.env.REACT_APP_BACKEND_URL}/images/upload`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`  // Add the Authorization header with the JWT token
            },
            body: JSON.stringify({ images: imagesData }),
            mode: 'cors',  // Ensure CORS mode is handled
            credentials: 'include'  // Include credentials if needed
        });

        if (response.ok) {
            const result = await response.json();
            console.log("Training initiated:", result);
            const taskId = result.task_id;
            setTrainingTaskId(taskId);
            setTrainingStatus('processing');
            // Start polling for training status
            pollTrainingStatus(taskId);
        } else {
            const errorData = await response.json();
            throw new Error(`Failed to initiate training: ${response.status} - ${JSON.stringify(errorData)}`);
        }
    } catch (error) {
        console.error("Error initiating training:", error);
        alert("Failed to initiate training, please try again.");
    }
  };

  const pollTrainingStatus = (taskId) => {
    const token = localStorage.getItem('access_token');
    const url = `${process.env.REACT_APP_BACKEND_URL}/images/training/status/${taskId}`;
    fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
    .then(response => response.json())
    .then(data => {
        console.log('Training status:', data);
        if (data.state === 'PENDING' || data.state === 'STARTED') {
            setTrainingStatus(data.state);
            setTimeout(() => pollTrainingStatus(taskId), 5000); // Poll every 5 seconds
        } else if (data.state === 'SUCCESS') {
            setTrainingStatus('completed');
            onComplete();  // Proceed to the next step or show success message
        } else {
            setTrainingStatus('failed');
            console.error('Training failed:', data.status);
            alert('Training failed. Please try again.');
        }
    })
    .catch(error => {
        console.error('Error fetching training status:', error);
        setTimeout(() => pollTrainingStatus(taskId), 5000); // Retry polling
    });
  };

  return (
    <motion.div
      className="camera-capture"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <h2>{reviewMode ? "Review and Recapture" : "Camera Capture"}</h2>
      {reviewMode && !selectedPhotoIndex && !trainingTaskId && (
        <>
          <p>If you need to recapture any photo, please select it from below.</p>
          <p>If all photos look good, you can proceed to the next step.</p>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={handleNext}
            style={{ marginTop: '20px' }}
          >
            Next Step
          </motion.button>
        </>
      )}
      {!reviewMode && (
        <p>{angles[currentAngle]}</p>
      )}
      {cameraActive && (
        <video ref={videoRef} style={{ width: '100%', height: 'auto' }} autoPlay />
      )}
      {selectedPhotoIndex !== null && !cameraActive && (
        <img src={photos[selectedPhotoIndex].data} style={{ width: '100%', height: 'auto' }} alt="Selected for recapture" />
      )}
      {(cameraActive || selectedPhotoIndex !== null) && !trainingTaskId && (
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={cameraActive ? capturePhoto : handleRecapture}
        >
          {cameraActive ? 'Capture' : 'Recapture'}
        </motion.button>
      )}
      {reviewMode && (
        <Slider {...settings}>
          {photos.map((photo, index) => (
            <motion.img
              key={index}
              src={photo.data}
              alt={`Captured ${angles[index]}`}
              onClick={() => handleImageClick(index)}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: index * 0.1 }}
              style={{ width: '100px', height: '100px', cursor: 'pointer' }}
            />
          ))}
        </Slider>
      )}
      {trainingStatus && (
        <div>
          <p>Training Status: {trainingStatus}</p>
        </div>
      )}
    </motion.div>
  );
};

export default CameraCapture;